import { RefObject, MouseEventHandler } from 'react';
import { Menu, MenuItem, Backdrop } from '@mui/material';
import { typography } from '../../theme';

import { getText } from '../../helpers/textHelper';
import NuiIcon from '../NuiIcon';
import { globals } from '../../theme';
import { useAppThemeColors } from '../../helpers/themeHelper';

interface HeaderMenuProps {
  target: RefObject<HTMLDivElement>;
  open: boolean;
  onAction: Function;
  onClose: MouseEventHandler<HTMLDivElement>;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({
  target,
  open,
  onAction,
  onClose,
}) => {
  const text = getText();
  const menuText = text.HEADER_MENU;
  const themeColors = useAppThemeColors();

  if (!target.current) return null;

  const options = [
    {
      id: 'app',
      icon: 'link',
      color: themeColors.primary,
    },
    { id: 'my-music', icon: 'mymusic', color: null },
    { id: 'settings', icon: 'settings', color: null },
    { id: 'disconnect', icon: 'cross-circle-small', color: null },
  ];

  return (
    <Backdrop open={open} onClick={onClose}>
      <Menu
        open={open}
        anchorEl={target.current}
        onClose={onClose}
        elevation={0}
        autoFocus={false}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -32,
          horizontal: 0,
        }}
        sx={(theme) => ({
          '& .MuiPaper-root': {
            borderRadius: 1,
            marginTop: theme.spacing(2),
            minWidth: 180,
            boxShadow: `0px 2px 16px ${globals.colors.black40}`,
            '& .MuiMenu-list': {
              padding: `${theme.spacing(1)} 0}`,
            },
            '& .MuiMenuItem-root': {
              height: theme.spacing(12),
              ...typography['body-demi'],
              svg: {
                marginRight: theme.spacing(3),
              },
              opacity: 1,
              transition: 'opacity 0.3s',
              '&:hover': {
                background: 'none',
                opacity: 0.6,
                transition: 'opacity 0.3s',
              },
              '&:active': {
                background: 'none',
              },
            },
          },
        })}>
        {options.map((item: any) => {
          const { id, icon, color } = item;
          const title = menuText[id.toUpperCase()];
          return (
            <MenuItem key={id} onClick={() => onAction(id)} disableRipple>
              <NuiIcon
                name={icon}
                size={6}
                color={color || globals.colors.white60}
              />
              {title}
            </MenuItem>
          );
        })}
      </Menu>
    </Backdrop>
  );
};

export default HeaderMenu;
