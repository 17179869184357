import { DialogContent, Stack, Box } from '@mui/material';

import { getText } from '../../helpers/textHelper';
import NuiDialog from '../NuiDialog';
import NuiTypography from '../NuiTypography';
import NuiButton from '../NuiButton';
import { globals } from '../../theme';

const id = 'disconnect';

const DialogDisconnect: React.FC<{ open: boolean; onAction: Function }> = ({
  open,
  onAction,
}) => {
  const text = getText();
  const dialogText = text.DIALOGS[id.toUpperCase()];

  return (
    <NuiDialog
      open={open}
      onClose={() => onAction('close', id)}
      sx={{
        '.MuiDialog-paper': {
          background: globals.colors.bgBase,
        },
      }}>
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          marginBottom={2}>
          <NuiTypography variant="title-bold" gutterBottom>
            {dialogText.TITLE}
          </NuiTypography>
        </Stack>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            marginBottom: 4,
          }}>
          <NuiTypography variant="body" textAlign="center">
            {dialogText.DESCRIPTION}
          </NuiTypography>
        </Box>
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          marginBottom={2}>
          <NuiButton color="primary" onClick={() => onAction('disconnect', id)}>
            {dialogText.CTA}
          </NuiButton>
          <NuiButton variant="text" onClick={() => onAction('close', id)}>
            {dialogText.CLOSE}
          </NuiButton>
        </Stack>
      </DialogContent>
    </NuiDialog>
  );
};

export default DialogDisconnect;
