import { useSearchParams } from 'react-router-dom';

const supportedThemes: string[] = ['default', 'christmas'];
const defaultTheme: string = supportedThemes[1];

const colors: any = {
  default: {
    primary: '#00FFFF',
    secondary: '#FD81F5',
    tertiary: '#00F1A3',
    quaternary: '#FF813A',
  },
  christmas: {
    primary: '#FF5B4E',
    secondary: '#76FB6C',
    tertiary: '#7C3FFF',
    quaternary: '#2259FF',
  },
};

// Functions
const getAppThemes = (): string[] => supportedThemes;

const getAppTheme = (): string => {
  const params = new URLSearchParams(window.location.search);
  const theme = params.get('theme');
  if (!theme) {
    return defaultTheme;
  } else if (supportedThemes.includes(theme)) {
    return theme;
  } else {
    console.warn(`Theme ${theme} not found, falling back to default`);
    return defaultTheme;
  }
};

const getAppThemeColor = (): string => {
  const cols: string[] = Object.values(colors[getAppTheme()]);
  return cols[Math.floor(Math.random() * cols.length)];
};

// Hooks
const useAppThemed = (variants: { [key: string]: any }): any => {
  let [searchParams] = useSearchParams();
  let theme = searchParams.get('theme') || defaultTheme;
  if (!variants[theme] && typeof variants[theme] !== 'boolean') {
    console.warn(`Variants for ${theme} not found, falling back to default`);
    theme = defaultTheme;
  }
  return variants[theme];
};

const useAppThemeColors = (): any => {
  return useAppThemed(colors);
};

export {
  getAppThemes,
  getAppTheme,
  getAppThemeColor,
  useAppThemed,
  useAppThemeColors,
};
