import { Box, keyframes, Rating as MuiRating, Stack } from '@mui/material';

import { getText } from '../../helpers/textHelper';
import { getAppTheme, useAppThemeColors } from '../../helpers/themeHelper';
import NuiIcon from '../NuiIcon';
import NuiTypography from '../NuiTypography';
import NuiLink from '../NuiLink';
import { globals } from '../../theme';

const animRating = (themeColors: any) =>
  `${keyframes`
  0% {
    fill: ${themeColors.quaternary};
  }
  33% {
    fill: ${themeColors.secondary};
  }
  66% {
    fill: ${themeColors.tertiary};
  }
  100% {
    fill: ${themeColors.primary};
  }
`} 0.6s linear forwards`;

const animFeedback = () =>
  `${keyframes`
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`} 0.3s 0.1s forwards`;

const RatingBaubleActive = () => {
  return (
    <Box
      sx={{
        width: 32,
        height: 32,
        svg: {
          width: '100%',
          height: 'auto',
        },
      }}>
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: 32, height: 32 }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4245 3.1494L13.4245 3.15088H14.1091C14.3244 3.15088 14.499 3.32545 14.499 3.5408V5.64525C18.0386 6.60241 20.6428 9.83649 20.6428 13.6788C20.6428 18.2746 16.9172 22.0002 12.3214 22.0002C7.72561 22.0002 4 18.2746 4 13.6788C4 9.83692 6.60357 6.60313 10.1426 5.64557V3.5408C10.1426 3.32545 10.3172 3.15088 10.5325 3.15088H11.0693L11.0693 3.1494C11.0693 2.51519 11.5971 2 12.2469 2C12.8968 2 13.4245 2.51519 13.4245 3.1494ZM12.2469 2.47135C12.6368 2.47135 12.9547 2.77666 12.9547 3.15088H11.5391C11.5392 2.77666 11.857 2.47135 12.2469 2.47135Z"
        />
        <path
          d="M13.8431 7.90525C12.0015 9.12824 10.7347 9.73582 9.67142 10.298C8.60815 10.8602 4.08729 13.065 4.30339 15.7929C4.30809 15.8461 4.31279 15.8978 4.31749 15.9495C4.51166 16.6338 4.7904 17.2836 5.14273 17.885C4.95012 17.2883 4.91411 16.164 6.58653 14.9754C9.04192 13.2294 14.2988 10.7427 15.2931 9.74835C16.2562 8.7853 16.9123 7.49967 16.4112 6.43326C16.0604 6.23596 15.6956 6.06214 15.3166 5.9165C15.3198 6.29703 15.1303 7.05494 13.8462 7.90681L13.8431 7.90525Z"
          fill="#FEF5D2"
        />
        <path
          d="M19.0805 8.82568C19.3029 9.19681 19.9183 10.8316 15.8124 13.5564C11.0144 16.7399 6.96799 17.4744 7.82142 20.6736C8.21291 20.9257 8.62475 21.1465 9.05695 21.3313C8.99901 20.9304 9.14934 20.1349 10.6965 19.1436C12.8434 17.7687 20.3802 14.1279 20.374 11.5661C20.3129 11.3327 20.2424 11.1041 20.161 10.8802C19.8979 10.1411 19.5315 9.45049 19.0805 8.82568Z"
          fill="#FEF5D2"
        />
      </svg>
    </Box>
  );
};

interface RatingProps {
  state: string;
  rating: number;
  onAction: Function;
}

const Rating: React.FC<RatingProps> = ({ state, rating, onAction }) => {
  const isActive = state === 'playlist' || state === 'saved';
  const isFeedback = rating > 0;
  const text = getText();
  const theme = getAppTheme();
  const themeColors = useAppThemeColors();
  const ratingText = text.RATING;
  const isChristmas = theme === 'christmas';
  const icon = isChristmas ? 'bauble' : 'star';

  return (
    <Stack direction="column" spacing={1} paddingTop={1}>
      {/* Title */}
      <NuiTypography
        variant="caption-smallest-bold"
        color={globals.colors.white40}
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          letterSpacing: 0.5,
        }}>
        {ratingText.TITLE}
      </NuiTypography>

      {/* Rating */}
      <Stack direction="column" spacing={2}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <MuiRating
            name="customized-color"
            defaultValue={0}
            value={rating}
            precision={1}
            disabled={!isActive}
            getLabelText={(value: number) =>
              `${value} Heart${value !== 1 ? 's' : ''}`
            }
            icon={
              isChristmas ? (
                <RatingBaubleActive />
              ) : (
                <NuiIcon
                  name={`${icon}-active`}
                  size={8}
                  color={themeColors.primary}
                />
              )
            }
            emptyIcon={
              <NuiIcon name={icon} size={8} color={globals.colors.white20} />
            }
            sx={{
              '.MuiRating-iconFilled': {
                svg: {
                  animation: animRating(themeColors),
                },
              },
            }}
            onChange={(e: any) => {
              onAction &&
                onAction('rating', { rating: parseInt(e.target.value) });
            }}
          />
        </Box>

        {/* Feedback */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            maxHeight: isFeedback ? 20 : 0,
            transition: 'max-height 0.3s',
          }}>
          <NuiTypography
            variant="caption"
            sx={{
              textAlign: 'center',
              opacity: 0,
              animation: isFeedback ? animFeedback() : null,
            }}>
            {ratingText.CONFIRMATION_1}
            <NuiLink
              variant="caption-demi"
              onClick={() => onAction && onAction('feedback')}
              sx={{ cursor: 'pointer' }}>
              {ratingText.CONFIRMATION_2}
            </NuiLink>
            {ratingText.CONFIRMATION_3}
          </NuiTypography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Rating;
