import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { getText } from '../../helpers/textHelper';
import {
  getAppTheme,
  getAppThemes,
  useAppThemeColors,
} from '../../helpers/themeHelper';

import NuiTypography from '../NuiTypography';
import NuiLink from '../NuiLink';
import { globals } from '../../theme';

interface LabsCardFooterSwitchProps {
  animLink: Function;
}
const LabsCardFooterSwitch: React.FC<LabsCardFooterSwitchProps> = ({
  animLink,
}) => {
  const text = getText();
  const themeCurrent = getAppTheme();
  const themesAvailable = getAppThemes();
  const themeColors = useAppThemeColors();
  let [, setSearchParams] = useSearchParams();

  // Switch themes
  const onNavigate = useCallback(() => {
    if (themesAvailable.length === 0) return;
    const themeIndex = themesAvailable.indexOf(themeCurrent);
    if (themeIndex < 0) return;
    const nextIndex = themeIndex + 1;
    const nextTheme = themesAvailable[nextIndex % themesAvailable.length];
    setSearchParams({ theme: nextTheme });
  }, [themeCurrent, themesAvailable, setSearchParams]);

  return (
    <>
      {/* Switch */}
      <Stack
        direction="row"
        spacing={1}
        sx={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <NuiTypography
          variant="caption-demi"
          textAlign="center"
          color={globals.colors.white60}>
          {text.INTRO_FOOTER}
        </NuiTypography>
        <NuiTypography
          variant="caption-demi"
          textAlign="center"
          color={globals.colors.white20}>
          {`|`}
        </NuiTypography>
        <NuiLink onClick={onNavigate} sx={{ textDecoration: 'none' }}>
          <NuiTypography
            variant="caption-demi"
            textAlign="center"
            color={globals.colors.white60}
            sx={{
              cursor: 'pointer',
              animation: animLink([
                themeColors.primary,
                themeColors.secondary,
                themeColors.quaternary,
              ]),
            }}>
            {text.INTRO_FOOTER_SWITCH}
          </NuiTypography>
        </NuiLink>
      </Stack>
    </>
  );
};

export default LabsCardFooterSwitch;
