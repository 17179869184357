import { useState, useEffect, useCallback, forwardRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, keyframes } from '@mui/material';

import { getType } from '../../helpers/interactionHelper';
import { isEmbed } from '../../helpers/embedHelper';
import { getText } from '../../helpers/textHelper';
import { useAppThemeColors, useAppThemed } from '../../helpers/themeHelper';
import NuiTextField, { NuiTextFieldProps } from '../NuiTextField';
import NuiIconButton from '../NuiIconButton';
import NuiIcon from '../NuiIcon';
import { globals } from '../../theme';

const animTextFieldOutline = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
`;

const initialStates: any = {
  icon: { name: 'magic' },
};

interface PromptProps {
  state: string;
  loading: boolean;
  prompt: string;
  onInput: Function;
  onSubmit: Function;
  onAction: Function;
}

const Prompt = forwardRef<NuiTextFieldProps, PromptProps>(
  ({ state, loading, prompt, onInput, onSubmit, onAction }, inputRef) => {
    const [active, setActive] = useState(false);
    const themeColors = useAppThemeColors();
    const colors = useAppThemed({
      default: {
        outline: ['#FF00D6', '#8043FF', '#00FFFF'],
        icon: themeColors.primary,
        background: globals.colors.black,
      },
      christmas: {
        outline: ['#FF3D2D', '#8043FF', '#76FB6C'],
        icon: themeColors.white,
        background: '#022320',
      },
    });
    const [icon, setIcon] = useState({
      ...initialStates.icon,
      color: colors.icon,
    });

    const text = getText();
    const embed = isEmbed();
    const isOutline = active || state === 'initial';
    const isBack = prompt.length > 1 && !loading;
    const isKeyboard = !isBack && !loading;

    // Update icon and send val to parent
    const handleInput = useCallback(
      (e: any) => {
        const val = e.target.value;
        const type = getType(val);
        let newIcon = {};
        const isPrompt = type === 'prompt' && val.length > 3;
        switch (type) {
          case 'napster':
            newIcon = { name: 'logo', color: colors.icon };
            break;
          case 'spotify':
            newIcon = { name: 'spotify', color: '#1ED760' };
            break;
          case 'apple':
            newIcon = { name: 'apple', color: globals.colors.white };
            break;
          case 'unsupported':
            newIcon = {
              name: 'cross',
              color: globals.colors.semanticErrorLight,
            };
            break;
          case 'prompt':
            newIcon = {
              name: isPrompt ? 'message-bubble' : initialStates.icon.name,
              color: themeColors.primary,
            };
            break;
          default:
            console.log(`Nothing for ${type}`);
            break;
        }
        setIcon(newIcon);
        onInput(val);
      },
      [onInput, colors.icon, themeColors.primary],
    );

    // Focus field on desktop
    useEffect(() => {
      // @ts-ignore
      if (!inputRef || !inputRef.current || isMobile) return;
      // @ts-ignore
      inputRef.current.focus();
    }, [inputRef]);

    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
        {/* Outline */}
        <Box
          sx={{
            position: 'absolute',
            width: 'calc(100% + 4px)',
            height: 'calc(100% + 4px)',
            borderRadius: 0.5,
            background: `linear-gradient(45deg,${colors.outline})`,
            backgroundSize: '200%',
            animation: `${animTextFieldOutline} 3s infinite linear alternate`,
            opacity: isOutline ? 1 : 0,
            transition: 'opacity 0.3s',
          }}
        />
        {/* Backgrounds */}
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: 0.5,
            backgroundColor: embed ? globals.colors.bgBase : colors.background,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: 0.5,
            backgroundColor: globals.colors.white5,
            transition: 'background-color 0.3s',
          }}
        />
        {/* Form, for iOS keyboard action */}
        <form
          action="."
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {/* Textfield */}
          <NuiTextField
            inputRef={inputRef}
            name="prompt"
            type="text"
            multiline
            maxRows={4}
            placeholder={text.INPUT_PLACEHOLDER}
            value={prompt}
            title={prompt}
            disabled={loading}
            InputProps={{
              startAdornment: (
                <NuiIcon
                  name={icon.name}
                  color={icon.color}
                  sx={{ marginLeft: globals.units(3.5) }}
                />
              ),
              autoComplete: 'off',
              autoCapitalize: 'off',
              autoCorrect: 'off',
              spellCheck: 'false',
            }}
            inputProps={{
              maxLength: 300,
            }}
            onChange={handleInput}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onSubmit();
              }
            }}
            sx={{ flex: 1, background: 'none' }}
          />

          {/* Back / Cancel */}
          {isBack && (
            <NuiIconButton
              icon="cross-small"
              sx={(theme) => ({
                '&.MuiIconButton-root.MuiIconButton-sizeMedium': {
                  width: theme.spacing(6),
                  height: theme.spacing(6),
                  marginRight: theme.spacing(2),
                  svg: {
                    width: 20,
                    height: 20,
                    fill: globals.colors.white60,
                  },
                },
              })}
              onClick={() => onAction('back')}
            />
          )}

          {/* Keyboard Icon */}
          {isKeyboard && (
            <Box
              sx={(theme) => ({
                position: 'relative',
                width: theme.spacing(6),
                height: theme.spacing(6),
                marginRight: theme.spacing(2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              })}>
              <NuiIcon
                name="keyboard"
                size={6}
                color={globals.colors.white60}
              />
            </Box>
          )}
        </form>
      </Box>
    );
  },
);

export default Prompt;
