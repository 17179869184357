import { Box, Stack, keyframes } from '@mui/material';

import { getText } from '../../helpers/textHelper';
import { useAppThemeColors } from '../../helpers/themeHelper';
import ErrorArtwork from './ErrorArtwork';
import NuiIcon from '../NuiIcon';
import NuiTypography from '../NuiTypography';
import NuiButton from '../NuiButton';
import { globals } from '../../theme';

const animIcon = (colors: string[]) =>
  `${keyframes`
  0% {
    fill: ${colors[0]};
  }
  33% {
    fill: ${colors[1]};
  }
  66% {
    fill: ${colors[2]};
  }
  100% {
    fill: ${colors[0]};
  }
`} 2s linear infinite`;

const getIcon = (id: string) => {
  switch (id) {
    case 'highdemand':
      return 'fire';
    case 'notracks':
      return 'help';
    case 'maintenance':
      return 'settings';
    case 'link':
      return 'cross-circle';
    default:
      return 'warning';
  }
};

const Error: React.FC<{
  error: string;
  onAction: Function;
}> = ({ error, onAction }) => {
  const text = getText();
  const themeColors = useAppThemeColors();
  const errorText = text.ERROR_MESSAGES[error.toUpperCase()];
  if (!errorText) {
    console.log(`Error message not found for error: ${error}`);
    return null;
  }

  const isCta = errorText.CTA;
  const icon = getIcon(error);
  const isArtwork = error === 'generic' || error === 'maintenance';

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(8),
      })}>
      <Stack spacing={2} marginBottom={isCta ? 6 : 0} alignItems="center">
        {/* Artwork or icon */}
        {isArtwork ? (
          <ErrorArtwork />
        ) : (
          <Box
            sx={{
              svg: {
                animation: animIcon([
                  themeColors.primary,
                  themeColors.secondary,
                  themeColors.quaternary,
                ]),
              },
            }}>
            <NuiIcon name={icon} size={16} color={globals.colors.white60} />
          </Box>
        )}
        {/* Title */}
        <NuiTypography variant="headline-bold" textAlign="center">
          {errorText.TITLE}
        </NuiTypography>
        {/* Description */}
        <NuiTypography
          variant="body"
          textAlign="center"
          color={globals.colors.white80}>
          {errorText.DESCRIPTION}
        </NuiTypography>
      </Stack>
      {/* CTA */}
      {isCta && (
        <NuiButton color="primary" onClick={() => onAction('cta', error)}>
          {errorText.CTA}
        </NuiButton>
      )}
    </Box>
  );
};

export default Error;
