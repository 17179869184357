import en from './locales/en.json';
import enChristmas from './locales/en-christmas.json';
import { getAppTheme } from './themeHelper';

const texts: any = { default: en, christmas: enChristmas };

const getText = (): any => {
  return texts[getAppTheme()];
};

export { getText };
