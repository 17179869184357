import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  requestAuthorizationCode,
  requestAccessToken,
  getNapiAccount,
  deleteAppCookies,
  refreshAccessToken,
} from './napiAuthHelper';
import { getCookie } from './cookieHelper';
import { getText } from './textHelper';
import { logEvent, logUser } from './analyticsHelper';
import { NapiCookieLabels } from '../@typings/Napi/NapiCookieLabels.enum';

const log: boolean = process.env.REACT_APP_LOG_DATA === 'true';
const _log = (type: string, message: string) => {
  if (!log) return;
  const isAuth = type === 'auth';
  console.log(isAuth ? `🔑 Auth: ${message}` : `👩‍🎤 Profile: ${message}`);
};

// Auth hook for screens
const useAuth = () => {
  const [auth, setAuth] = useState<AuthTypes>(null);
  const [profile, setProfile] = useState<ProfileTypes>({});
  const isConnected = !!getCookie(NapiCookieLabels.ACCESS_TOKEN);
  const [searchParams] = useSearchParams();
  const text = getText();

  // Disconnect, reset cookies
  const clearAuth = useCallback(() => {
    deleteAppCookies();
    setAuth(null);
    setProfile({});
    logEvent('auth', { id: 'disconnected' });
    logUser(null);
    _log('auth', `Disconnected`);
  }, []);

  // Connect using Oauth window
  const getAuth = useCallback(
    async (email: string, password: string) => {
      try {
        const authorizationCode = await requestAuthorizationCode();
        let res = await requestAccessToken(authorizationCode, email, password);
        // OK, update Auth token
        setAuth(res.token);
        _log('auth', `Connected`);
        logEvent('auth', { id: 'connected' });
        return { ok: true, auth: res.token };
      } catch (e: any) {
        const status = e?.response?.status;
        if (status === 403) {
          // Forbidden (incorrect details)
          logEvent('error', {
            id: 'auth',
            message: 'wronguserpass',
          });
          alert(text.ERROR_MESSAGES.SIGNIN.DESCRIPTION);
          return { ok: false };
        } else if (status === 401) {
          // Refresh token
          await refreshAccessToken();
          alert(text.ERROR_MESSAGES.GENERIC.DESCRIPTION);
        } else {
          // Generic error
          alert(text.ERROR_MESSAGES.GENERIC.DESCRIPTION);
        }
        logEvent('error', { id: 'auth', status, message: e.message });
        console.error(e);
        return { ok: false };
      }
    },
    [text],
  );

  // Get user profile
  const getProfile = useCallback(async (token: NapiToken) => {
    try {
      if (!token) {
        return console.log('No auth to get profile');
      }
      const res: any = await getNapiAccount(token);
      if (!res.id || !res.profile) return { ok: false };
      // Set profile
      const { id, userName, screenName, lang, profile } = res;
      const newProfile = {
        ok: true,
        id,
        name: screenName || userName,
        email: userName,
        lang,
        img: profile?.avatar?.url,
      };
      setProfile(newProfile);
      _log('profile', `Got Profile`);
      logUser(id);
      logEvent('profile', {
        id: 'connected',
        profile: newProfile,
      });
      return { ok: true, profile: newProfile };
    } catch (e) {
      console.error(e);
      logEvent('error', { id: 'profile' });
      return { ok: false };
    }
  }, []);

  // Get auth if we have in cookies
  useEffect(() => {
    const update = async () => {
      if (auth) return;
      if (!isConnected || profile.ok) return;
      if (auth) {
        _log('auth', `>> From state`);
        await getProfile(auth);
      } else {
        _log('auth', `>> From refresh token`);
        const newToken = await refreshAccessToken();
        await getProfile(newToken.token);
        setAuth(newToken.token);
      }
    };
    update();
  }, [searchParams, auth, profile, isConnected, getProfile]);

  return { auth, profile, getAuth, clearAuth };
};

export { useAuth };
