import { Snackbar as MuiSnackbar } from '@mui/material';
import { getText } from '../../helpers/textHelper';
import NuiButton from '../NuiButton/NuiButton';

const Snackbar: React.FC<{
  open: boolean;
  id: string | null;
  action: string | null;
  onClose: any;
  onAction?: any;
}> = ({ open, id, action, onClose, onAction }) => {
  if (!id) return null;
  const text = getText();
  const snackbarsText = text.SNACKBARS;
  const textId = id.toUpperCase().replaceAll('-', '_');
  const { TITLE, CTA } = snackbarsText[textId];

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      message={TITLE}
      autoHideDuration={5000}
      onClose={onClose}
      onClick={onClose}
      action={
        action ? (
          <NuiButton
            color="primary"
            size="small"
            onClick={() => onAction(action)}>
            {CTA}
          </NuiButton>
        ) : null
      }
    />
  );
};

export default Snackbar;
